import {MODALS, TYPES_FORM} from "../constants/homepage"
import { setToCookie, getFromCookie } from './cookie.service'

export const storageManualAddress = (manualAddress, typeOfService, typeOfForm) => {

   if(TYPES_FORM.KE_CZ === typeOfForm && typeOfService === MODALS.ADDING_MANUALY_PICKUP){
      setToCookie(MODALS.COOKIE_SETTING.manualKeCzPickup, JSON.stringify(manualAddress))

   }else if(TYPES_FORM.KE_CZ === typeOfForm && typeOfService === MODALS.ADDING_MANUALY_DELIVERY){
      setToCookie(MODALS.COOKIE_SETTING.manualKeCzDelivery,JSON.stringify(manualAddress))

   }else if(TYPES_FORM.KE_KE === typeOfForm && typeOfService === MODALS.ADDING_MANUALY_PICKUP){
      setToCookie(MODALS.COOKIE_SETTING.manualKeKePickUp, JSON.stringify(manualAddress))

   }else if(TYPES_FORM.KE_KE === typeOfForm && typeOfService === MODALS.ADDING_MANUALY_DELIVERY){
      setToCookie(MODALS.COOKIE_SETTING.manualKeKeDelivery,JSON.stringify(manualAddress))

   }
}


export const getManualAddres = (key) => {
   const cookieData = getFromCookie(key)
   try {
      return JSON.parse(cookieData)
  } catch (error) {
      return cookieData
  }
}
import { setToCookie, getFromCookie } from './cookie.service'
import {DELIVERY_ADDRESS_KEY, PICKUP_ADDRESS_KEY} from "../constants/last-address";


export const setAddressesToStorage = (pickup, delivery) => {
    
    
    setToCookie(DELIVERY_ADDRESS_KEY, JSON.stringify(delivery))
    setToCookie(PICKUP_ADDRESS_KEY, JSON.stringify(pickup))
}

export const getAddressByKey = (key) => {
    const cookieData = getFromCookie(key)
    try {
        return JSON.parse(cookieData)
    } catch (error) {
        return cookieData
    }
}

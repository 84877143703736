import React from "react";
import classes from "./dropdown-arrow-icon.module.scss";
import classNames from "classnames";

function DropdownArrowIcon({ active = false }) {
  return (
    <svg
      width="24"
      height="24" 
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(classes["arrowIcon"], {[`${classes["active"]}`]: active})}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DropdownArrowIcon;

import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styles from "./delivery.ke.cz.module.scss";
import Header from "../../components/header";
import CountryMapImage from "../../components/country-map-image";
import DayTime from "../../components/day-time";
import DeliveryForm from "../../components/delivery-form";
import BoxImage from "../../components/box-image";
import ColorBox from "../../components/slider-images/box-image";
import InfoIcon from "../../components/info-icon";
import PriceCard from "../../components/price-card";
import { listPriceCards, TYPES_FORM } from "../../constants/homepage";
import FaqQuestion from "../../components/faq-question";
import CompaniesForDeliver from "../../components/companies-for-deliver";
import ContactUs from "../../components/contact-us";
import Footer from "../../components/footer";
import Page from "../../components/page";
import { HOUR_TO_PICKUP } from "../../constants/ke_cz";
import { ANIMATION_TYPE } from "../../constants/animation";
import flagCh from "../../components/flagCzech/flag-czechia-3088.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { KEY_ACCEPTED_COOKIES } from "../../constants/homepage";
import { getAcceptedCookie } from "../../services/acceptedCookie";
import CookieBanner from "../../components/cookie-banner/index";
import {
  CHAT_LINK,
  INFO_PACKAGE,
  QUESTIONS_KE_CZ,
} from "../../constants/homepage";

const genarateHtmlText = () => {
  const currentTime = new Date();
  return (
    <>
      <p>
        {`Vyzdvihujeme ${
          currentTime.getHours() < HOUR_TO_PICKUP ? "dnes" : "zítra"
        } do ${HOUR_TO_PICKUP}:00.`}
        <b>Doručujeme zajtra</b>
      </p>
    </>
  );
};

function DeliveryKeCz() {
  const [isAcceptedCookie, setIsAcceptedCookie] = useState(
    getAcceptedCookie(KEY_ACCEPTED_COOKIES)
  );

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Page>
      <main>
        <section className={styles.wrapper}>
          <Header showNav />
        </section>
        <section className={styles.countryImg} data-aos={ANIMATION_TYPE}>
          <CountryMapImage />
        </section>
        <section className={styles.titleContent} data-aos={ANIMATION_TYPE}>
          <h1 className={styles.title}>
            Doručenie z Košíc do
            <span className={styles.wrapperFlag}>
              <img src={flagCh} className={styles.imgFlag}></img>{" "}
            </span>
            Česka na druhý deň
          </h1>
          <div className={styles.time}>
            <DayTime Text={genarateHtmlText} />
          </div>
          <p className={styles.text}>
            Odošlite bez čakania v radoch Vaše balíky kdekoľvek do Českej
            Republiky s doručením už na ďalší pracovný deň.
          </p>
        </section>
        <section className={styles.shipment}>
          <div className={styles.deliveryForm} data-aos={ANIMATION_TYPE}>
            <DeliveryForm
              isHomePage={false}
              type={TYPES_FORM.KE_CZ}
              aditionalClass
            />
          </div>
          <h2 className={styles.subtitle} data-aos={ANIMATION_TYPE}>
            Akú veľkú zásielku môžem poslať?
          </h2>
          <div className={styles.dimensions} data-aos={ANIMATION_TYPE}>
            <div>
              <ColorBox />
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.info}>
                <div>
                  <BoxImage />
                </div>
                <div className={styles.size}>
                  Max. rozmery: <strong>{INFO_PACKAGE.maxSize}</strong>
                  <br />
                  Max. hmotnosť: <strong>{INFO_PACKAGE.maxWeight}</strong>
                  <br />
                  Balík nie je nutné zabaliť do{" "}
                  <strong>kartónovej krabice</strong>
                </div>
              </div>
              <div className={styles.contact}>
                <InfoIcon />
                <span>
                  Chcete poslať balík, ktorý presahuje tieto rozmery?{" "}
                  <a href={CHAT_LINK}>Napíšte nám na chat</a>
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.wrapper} data-aos={ANIMATION_TYPE}>
          <div className={styles.priceContainer}>
            <h2 className={styles.subtitle}>
              Cenník doručenia z Košíc do Česka?
            </h2>
            <div className={styles.price}>
              <PriceCard navigateTo="/ke-cz" {...listPriceCards[0]} />
            </div>
          </div>
        </section>
        <section data-aos={ANIMATION_TYPE}>
          <FaqQuestion
            tabs={false}
            title="Časté otázky ohľadne doručenia z Košíc do Česka"
            showMore={true}
            typeQuestions={QUESTIONS_KE_CZ}
          />
        </section>
        <section data-aos={ANIMATION_TYPE}>
          <CompaniesForDeliver
            title="Doručujeme pre fyzické osoby aj pre firmy"
            className={styles.border}
          />
        </section>
        <section data-aos={ANIMATION_TYPE}>
          <ContactUs />
        </section>
        <section>
          <Footer />
        </section>
        {isAcceptedCookie === null ? (
          <CookieBanner setIsAcceptedCookie={setIsAcceptedCookie} />
        ) : (
          ""
        )}
      </main>
    </Page>
  );
}

export default DeliveryKeCz;

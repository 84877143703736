import React from "react";

function QuotesIcon() {
    return (
        <svg width="58" height="38" viewBox="0 0 58 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.65 37.5C5.25 37.5 3.35 37 1.95 36C0.649999 35 0 33.5 0 31.5C0 30.8 0.0499994 30.05 0.15 29.25C0.25 28.45 0.4 27.65 0.6 26.85C1 24.95 1.65 22.85 2.55 20.55C3.45 18.15 4.6 15.75 6 13.35C7.5 10.95 9.2 8.6 11.1 6.3C13.1 3.89999 15.4 1.79999 18 0H27.75C24.05 3.2 21.05 6.35 18.75 9.44999C16.55 12.45 14.65 15.65 13.05 19.05C16.35 19.85 18 21.7 18 24.6C18 25.3 17.95 26.05 17.85 26.85C17.75 27.65 17.6 28.45 17.4 29.25C17.2 30.25 16.9 31.25 16.5 32.25C16.1 33.25 15.5 34.15 14.7 34.95C14 35.65 13.05 36.25 11.85 36.75C10.75 37.25 9.35 37.5 7.65 37.5ZM37.2 37.5C34.8 37.5 32.9 37 31.5 36C30.2 35 29.55 33.5 29.55 31.5C29.55 30.8 29.6 30.05 29.7 29.25C29.8 28.45 29.95 27.65 30.15 26.85C30.55 24.95 31.2 22.85 32.1 20.55C33 18.15 34.15 15.75 35.55 13.35C37.05 10.95 38.75 8.6 40.65 6.3C42.65 3.89999 44.95 1.79999 47.55 0H57.3C53.6 3.2 50.6 6.35 48.3 9.44999C46.1 12.45 44.2 15.65 42.6 19.05C45.9 19.85 47.55 21.7 47.55 24.6C47.55 25.3 47.5 26.05 47.4 26.85C47.3 27.65 47.15 28.45 46.95 29.25C46.75 30.25 46.45 31.25 46.05 32.25C45.65 33.25 45.05 34.15 44.25 34.95C43.55 35.65 42.6 36.25 41.4 36.75C40.3 37.25 38.9 37.5 37.2 37.5Z" fill="#C0C0C0" />
        </svg>
    );
}

export default QuotesIcon;

import { addDaysToDate } from "src/helpers/additionalFuctions";
import { today } from "./calendars";

export const DeliveryOptionData = [
    {
        labelProps: {
            weight: "bold"
        },
        date: today,
        timeData: {
            hour: 10,
        },
        day: "Dnes",
        id: 1,
    },
    {
        labelProps: {},
        date: addDaysToDate(1),
        day: "Zajtra",
        timeData: {
            hour: 10,
        },
        id: 2,
    },
    {
        labelMessage: "Zvoliť vlastný dátum",
        id: 3,
        type: "custom"
    }
];

export const DeliveryMobileOptionData = [
    {
        date: today,
        day: "Dnes",
        id: 1,
    },
    {
        date: addDaysToDate(1),
        day: "Zajtra",
        id: 2,
    },
    {
        labelMessage: "Iný dátum vyzdvihnutia",
        id: 3,
        type: "custom"
    }
];


export const breakPointsForKe_СZ = {
    tablet: 760
  }
export const HOUR_TO_PICKUP = 14;

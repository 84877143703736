import React from "react";
import DropdownArrowIcon from "../dropdown-arrow-icon";
import classes from "./button-with-icon.module.scss";
import classNames from "classnames";
const ButtonWithIcon = function ({ type = "button", icon, children, className, ...props }) {
    return (
        <button className={classNames(classes["button"], className)} type={type} {...props}>
            {icon && <span className={classes["icon"]}>{icon}</span> }
            <span className={classes["text"]}>{children}</span>
        </button>
    );
};

export default ButtonWithIcon;

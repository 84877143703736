import React from "react";

function BoxImage() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="36"
            fill="none"
            viewBox="0 0 34 36"
        >
            <path
                fill="#000"
                d="M33.781 17.375l-4-5c-.013-.016-.031-.024-.045-.04a1.33 1.33 0 00-.248-.202c-.015-.008-.026-.02-.04-.028l-9-4.5a1 1 0 00-.895 1.79L26.763 13 17 17.882 7.236 13l7.211-3.605a1 1 0 00-.894-1.79l-9 4.5c-.016.008-.027.02-.042.03a.97.97 0 00-.086.059.985.985 0 00-.163.143c-.013.015-.03.023-.043.039l-4 5a1 1 0 00.334 1.519L4 20.618V29a1 1 0 00.553.895l12 6c.014.007.031.004.046.01a.899.899 0 00.802 0c.015-.006.032-.003.046-.01l12-6A1 1 0 0030 29v-8.382l3.447-1.723a1 1 0 00.334-1.52zM5.276 14.256l10.17 5.085-2.722 3.403-7.277-3.639-2.893-1.446 2.722-3.403zM6 21.618l6.553 3.277c.125.059.262.091.4.094.016 0 .03.011.046.011.026 0 .05-.015.075-.017a.98.98 0 00.278-.062.984.984 0 00.428-.296L16 21.85v11.53l-10-5v-6.763zm22 6.764l-10 5V21.85l2.219 2.774a.982.982 0 00.426.295c.09.035.186.057.283.064.024.002.047.016.072.016.015 0 .03-.01.045-.01a.983.983 0 00.402-.095L28 21.617v6.764zm.553-9.277l-7.278 3.64-2.721-3.404 10.17-5.085 2.722 3.403-2.893 1.447z"
            ></path>
            <path
                fill="#000"
                d="M13.293 11.293a1 1 0 000 1.414l3 3a.997.997 0 001.087.217h.003a.985.985 0 00.293-.196c.01-.01.022-.012.03-.021l3-3a1 1 0 00-1.413-1.414L18 12.586V1a1 1 0 10-2 0v11.586l-1.293-1.293a1 1 0 00-1.414 0z"
            ></path>
        </svg>
    );
}

export default BoxImage;

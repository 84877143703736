import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./delivery.form.module.scss";
import FormGroup from "../form-group";

import {
  MODALS,
  TYPES_FORM,
  DESTINATIONS,
  KEY_ACCEPTED_COOKIES,
} from "../../constants/homepage";
import Button from "../button";
import DeliveryInfoSection from "../delivery-info-section";
import ModalWindow from "../modal-window";
import SearchAddress from "../search-address";
import ManualAddressHandleForm from "../manula-address-handle-form/manual-address-handle-form";
import { setAddressesToStorage } from "../../services/last-address.service";
import { navigate } from "gatsby";
import { getAddressByKey } from "../../services/last-address.service";
import { allPlaceForSearchingForPickup } from "../../constants/homepage";
import classNames from "classnames";
import {
  DELIVERY_ADDRESS_KEY,
  PICKUP_ADDRESS_KEY,
} from "../../constants/last-address";
import { validateZip } from "src/constants/validation";
import {
  changeSpaceAtPostCode,
  upperCaseFirst,
} from "src/helpers/additionalFuctions";
import { spacePostCode } from "../../constants/patterns";
import { getAcceptedCookie } from "../../services/acceptedCookie";
import {
  storageManualAddress,
  getManualAddres,
} from "../../services/manualAddress";

function DeliveryForm({ isHomePage, type, aditionalClass }) {
  const [openModal, setOpenModal] = useState(false);
  const [pickupAddress, setPickupAddress] = useState({
    description: "",
    geoCode: "",
    placeId: "",
    isSelectedAddress: false,
    isManual: false,
  });
  const [deliveryAddress, setDeliveryAddress] = useState({
    description: "",
    geoCode: "",
    placeId: "",
    isSelectedAddress: false,
    isManual: false,
  });
  const [isPickupError, setIsPickupError] = useState(false);
  const [isDeliveryError, setIsDeliveryError] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isOpenDropDownPickup, setIsOpenDropDownPickup] = useState(false);
  const [isOpenDropDownDelivery, setIsOpenDropDownDelivery] = useState(false);
  const [isErrorZipCodeValidate, setIsErrorZipCodeValidate] = useState("");

  const getPlaseWordingByType = () => {
    if (type === TYPES_FORM.KE_CZ) {
      return {
        placeDelivery: "Českej republike",
        typeForm: type,
      };
    } else {
      return {
        placeDelivery: "Košiciach",
        typeForm: type,
      };
    }
  };

  const submitHandler = async () => {
    if (type === TYPES_FORM.KE_CZ) {
      if (
        allPlaceForSearchingForPickup.every(
          (el) => pickupAddress.description.includes(el) === false
        )
      ) {
        setIsPickupError(true);
        return;
      } else if (
        !deliveryAddress.description.includes(
          DESTINATIONS.country.CzechRepublic
        )
      ) {
        setIsDeliveryError(true);
        return;
      }
    }
    if (type === TYPES_FORM.KE_KE) {
      if (
        allPlaceForSearchingForPickup.every(
          (el) => pickupAddress.description.includes(el) === false
        )
      ) {
        setIsPickupError(true);
        return;
      } else if (
        allPlaceForSearchingForPickup.every(
          (el) => deliveryAddress.description.includes(el) === false
        )
      ) {
        setIsDeliveryError(true);
        return;
      }
    }

    if (getAcceptedCookie(KEY_ACCEPTED_COOKIES) !== false) {
      setAddressesToStorage(pickupAddress, deliveryAddress);
    }
    navigate(type === TYPES_FORM.KE_CZ ? "/ke-cz" : "/ke-ke", {
      state: {
        pickupAddress,
        deliveryAddress,
      },
    });
  };

  const addManualyProp = (data, country) => {
    return {
      ...data,
      latitude: "",
      longitude: "",
      city: upperCaseFirst(data.city),
      country: country,
      postcode: changeSpaceAtPostCode(spacePostCode, data.postcode),
      isManual: true,
    };
  };

  const manualyHandler = (address) => {
    const typeForm = getPlaseWordingByType().typeForm;
    if (openModal === MODALS.ADDING_MANUALY_DELIVERY) {
      const country =
        TYPES_FORM.KE_KE === typeForm
          ? DESTINATIONS.country.Slovakia
          : DESTINATIONS.country.CzechRepublic;
      setDeliveryAddress({
        ...deliveryAddress,
        description: `${upperCaseFirst(address.city)}, ${address.street}, ${
          address.postcode
        }`,
        geoCode: {
          ...addManualyProp(address, country),
        },
        isSelectedAddress: true,
        isManual: true,
      });

      setIsOpenDropDownDelivery(false);
    }
    if (openModal === MODALS.ADDING_MANUALY_PICKUP) {
      const country = DESTINATIONS.country.Slovakia;
      let ErrorManulaValidate = validateZip(address.postcode);
      if (typeof ErrorManulaValidate === "string") {
        setIsErrorZipCodeValidate(ErrorManulaValidate);
        return;
      } else {
        setPickupAddress({
          ...pickupAddress,
          description: `${upperCaseFirst(address.city)}, ${address.street}, ${
            address.postcode
          }`,
          geoCode: {
            ...addManualyProp(address, country),
            latitude: "",
            longitude: "",
          },
          isSelectedAddress: true,
          isManual: true,
        });
        setIsOpenDropDownPickup(false);
      }
    }
    if (getAcceptedCookie(KEY_ACCEPTED_COOKIES) !== false) {
      storageManualAddress(
        address,
        openModal,
        getPlaseWordingByType().typeForm
      );
    }
    setOpenModal(false);
  };

  const _OnChangePickUpAddress = (value = "", geoCode = {}, placeId = "") => {
    if ((value !== "" && Object.keys(geoCode).length) || value === "") {
      setIsOpenDropDownPickup(false);
      setIsPickupError(false);
      setIsOpenDropDownPickup(false);
    } else if (value !== "") {
      setIsOpenDropDownPickup(true);
      setIsPickupError(false);
    } else {
      setIsPickupError(false);
      setIsOpenDropDownPickup(false);
    }
    setPickupAddress({
      description: value,
      geoCode: geoCode,
      placeId: placeId,
      isSelectedAddress: Object.keys(geoCode).length ? true : false,
      isManual: false,
    });
  };

  const _OnChangeDeliveryAddress = (value = "", geoCode = {}, placeId = "") => {
    if ((value !== "" && Object.keys(geoCode).length) || value === "") {
      setIsOpenDropDownDelivery(false);
      setIsDeliveryError(false);
    } else if (value !== "") {
      setIsOpenDropDownDelivery(true);
      setIsDeliveryError(false);
    } else {
      setIsDeliveryError(false);
      setIsOpenDropDownDelivery(false);
    }
    setDeliveryAddress({
      description: value,
      geoCode: geoCode,
      placeId: placeId,
      isSelectedAddress: Object.keys(geoCode).length ? true : false,
      isManual: geoCode.isManual ? geoCode.isManual : false,
    });
  };

  const onClickManuallyAdd = (typeOfService) => {
    setOpenModal(typeOfService);
    setIsDeliveryError(false);
    setIsPickupError(false);
  };

  const onCloseModalWindow = () => {
    setIsErrorZipCodeValidate("");
    setOpenModal(false);
  };

  useEffect(() => {
    if (pickupAddress.isSelectedAddress && deliveryAddress.isSelectedAddress) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [isDisable, pickupAddress, deliveryAddress]);

  const onCheckIsLastManualAddress = (typeOfservice, typeOfForm) => {
    if (
      typeOfForm === TYPES_FORM.KE_CZ &&
      typeOfservice === MODALS.ADDING_MANUALY_PICKUP
    ) {
      return getManualAddres(MODALS.COOKIE_SETTING.manualKeCzPickup);
    } else if (
      typeOfForm === TYPES_FORM.KE_CZ &&
      typeOfservice === MODALS.ADDING_MANUALY_DELIVERY
    ) {
      return getManualAddres(MODALS.COOKIE_SETTING.manualKeCzDelivery);
    } else if (
      typeOfForm === TYPES_FORM.KE_KE &&
      typeOfservice === MODALS.ADDING_MANUALY_PICKUP
    ) {
      return getManualAddres(MODALS.COOKIE_SETTING.manualKeKePickUp);
    } else if (
      typeOfForm === TYPES_FORM.KE_KE &&
      typeOfservice === MODALS.ADDING_MANUALY_DELIVERY
    ) {
      return getManualAddres(MODALS.COOKIE_SETTING.manualKeKeDelivery);
    }
  };

  const adjustOpeningModal = () => {
    return (
      openModal === MODALS.ADDING_MANUALY_DELIVERY ||
      openModal === MODALS.ADDING_MANUALY_PICKUP
    );
  };
  return (
    <>
      <ModalWindow
        isOpen={adjustOpeningModal}
        onClose={onCloseModalWindow}
        title={"Pridať adresu ručne"}
        body={
          <ManualAddressHandleForm
            lastManualAddress={() =>
              onCheckIsLastManualAddress(
                openModal,
                getPlaseWordingByType().typeForm
              )
            }
            typeOfForm={getPlaseWordingByType().typeForm}
            typeOfService={openModal}
            onSubmit={manualyHandler}
            isErrorZipCodeValidate={isErrorZipCodeValidate}
            setIsErrorZipCodeValidate={setIsErrorZipCodeValidate}
          />
        }
      />
      <div
        className={classNames(styles.formBody, {
          [`${styles.formBodyAditional}`]: aditionalClass,
        })}
      >
        <div
          className={classNames(styles.formGroups, {
            [`${styles.formGroupsAdditional}`]: aditionalClass,
          })}
        >
          <FormGroup className={styles["formGroup"]}>
            <label>Adresa vyzdvihnutia v Košiciach</label>
            <SearchAddress
              onClickManuallyAdd={() =>
                onClickManuallyAdd(
                  MODALS.ADDING_MANUALY_PICKUP,
                  getPlaseWordingByType().typeForm
                )
              }
              isOpenDrop={isOpenDropDownPickup}
              setIsOpenDropDown={setIsOpenDropDownPickup}
              onChange={_OnChangePickUpAddress}
              address={pickupAddress.description}
              lastAddress={getAddressByKey(PICKUP_ADDRESS_KEY)}
              typeForm={getPlaseWordingByType().typeForm}
              clearError={setIsPickupError}
              name={"pickUp"}
            />
          </FormGroup>
          {isPickupError && (
            <div className={styles.errorBlock}>
              Adresa vyzdvihnutia sa musí nachádzať v Košiciach
            </div>
          )}
          <FormGroup className={styles["formGroup"]}>
            <label>
              Adresa doručenia v {getPlaseWordingByType().placeDelivery}
            </label>
            <SearchAddress
              onClickManuallyAdd={() =>
                onClickManuallyAdd(
                  MODALS.ADDING_MANUALY_DELIVERY,
                  getPlaseWordingByType().typeForm
                )
              }
              isOpenDrop={isOpenDropDownDelivery}
              setIsOpenDropDown={setIsOpenDropDownDelivery}
              onChange={_OnChangeDeliveryAddress}
              address={deliveryAddress.description}
              lastAddress={getAddressByKey(DELIVERY_ADDRESS_KEY)}
              typeForm={getPlaseWordingByType().typeForm}
              clearError={setIsDeliveryError}
              name={"delivery"}
            />
          </FormGroup>
          {isDeliveryError && (
            <div className={styles.errorBlock}>
              Adresa vyzdvihnutia sa musí nachádzať v{" "}
              {getPlaseWordingByType().placeDelivery}
            </div>
          )}
          <div className={styles["buttonSection"]}>
            <Button
              color={"light-green"}
              type={"submit"}
              hover={"opacity"}
              onClick={!isDisable ? submitHandler : () => {}}
            >
              Pokračovať
            </Button>
          </div>
        </div>
        {isHomePage && (
          <div className={styles["deliverySectionWrapper"]}>
            <DeliveryInfoSection />
          </div>
        )}
      </div>
    </>
  );
}

DeliveryForm.propTypes = {
  isHomePage: PropTypes.bool,
  type: PropTypes.string,
};

DeliveryForm.defaultProps = {
  isHomePage: true,
  type: TYPES_FORM.KE_CZ,
};

export default DeliveryForm;

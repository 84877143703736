import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "../../pages/for-companies/for.companies.module.scss";
import QuotesIcon from "../../icons/for-companies/quotes";
import palkovic from "../../icons/faq-company/Palkovič/palkovic.png";
import vapeklub from "../../icons/faq-company/vapeklub/vapeklub.png";
import alza from "../../icons/faq-company/alza/alza.png";
import gymbeam from "../../icons/faq-company/gymbeam/gymbeam.png";
import { getRandomNumber } from "src/helpers/additionalFuctions";
import { FEEDBACKS_FORM_COMPINIES } from "../../constants/for_companies";

function CompaniesForDeliver({ title, className }) {
  const [feedBack, setFeedBack] = useState(null);

  useEffect(() => {
    setFeedBack(
      FEEDBACKS_FORM_COMPINIES[getRandomNumber(FEEDBACKS_FORM_COMPINIES.length)]
    );
  }, []);

  if (!feedBack) {
    return null;
  }
  return (
    <div>
      <div className={classNames(styles.companies, className)}>
        <h2 className={styles.subtitle}>{title}</h2>
        <div className={styles.logos}>
          <div>
            <img src={palkovic} alt="Palkovič logo" width="152" />
          </div>
          <div>
            <img src={gymbeam} alt="Gymbeam logo" width="154" />
          </div>
          <div>
            <img src={alza} alt="Alza logo" width="130" />
          </div>
          <div>
            <img src={vapeklub} alt="Vapeklub logo" width="154" />
          </div>
        </div>
        <div className={styles.moreCompanies}>+ 73 ďalších firiem</div>
      </div>
      <section className={styles.wrapper}>
        <div className={styles.feedback}>
          <div className={styles.quotes}>
            <QuotesIcon />
          </div>
          <div>
            <img
              src={feedBack.logo}
              alt={`${feedBack.companyName} logo`}
              width="138"
            />
          </div>
          <p className={styles.feedbackText}>{feedBack.message}</p>
          <div className={styles.feedbackAuthor}></div>
        </div>
      </section>
    </div>
  );
}
CompaniesForDeliver.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};
CompaniesForDeliver.defaultProps = {
  className: "",
};
export default CompaniesForDeliver;

import React, { useState } from "react";
import priceCardClasses from "./price-card.module.scss";
import Button from "../button";
import classNames from "classnames";
import DropdownArrowIcon from "../dropdown-arrow-icon";
import ButtonWithIcon from "../button-with-icon";
import { Collapse } from "react-collapse";
import Underline from "../undreline";
import { navigate } from 'gatsby'

const PriceCard = function ({ goodOptions, descriptionOptions, className, navigateTo }) {
  const { title, cost, description, direction } = goodOptions;
  const { placeTitle, places, optionsTitle, options } = descriptionOptions;
  const [accordion, setAccordion] = useState(false);
  return (
    <div
      className={classNames(
        priceCardClasses["card"],
        className,
        "price-collapse-mobile"
      )}
    >
      <div className={priceCardClasses["leftPath"]}>
        <div className={priceCardClasses["wrapper"]}>
          <div className={priceCardClasses["priceTextSection"]}>
            <p className={priceCardClasses["title"]}>{title}</p>

            <p className={priceCardClasses["cost"]}>{cost} €</p>

            <p className={priceCardClasses["description"]}>
              {direction === 1 ? (<span>S vyzdvihnutím do 12:00 a doručením na ďalší pracovný deň</span>) : ""}
              {direction === 2 ? (
              <span>Štandard s doručením v rovnaký deň</span>) : ""}
            </p>
          </div>
          <Button
              color={"green"}
              hover={"opacity"}
              className={priceCardClasses["button"]}
              onClick={() => navigate(navigateTo)}
          >Objednať</Button>
        </div>
      </div>
      <Collapse isOpened={accordion}>
        <div className={priceCardClasses["rightPath"]}>
          <div className={priceCardClasses["wrapper"]}>
            <div className={priceCardClasses["rightPathSection"]}>
              <div className={priceCardClasses["title"]}>{placeTitle}</div>
              <div className={priceCardClasses["places"]}>
                {places.map((place, key) => (
                  <span key={key}>
                    {place}
                    {key + 1 !== places.length && ", "}
                  </span>
                ))}
              </div>
            </div>
            <div className={priceCardClasses["rightPathSection"]}>
              <div className={priceCardClasses["title"]}>{optionsTitle}</div>
              <div className={priceCardClasses["places"]}>
                <ul>
                  {options.map((option, key) => (
                    <li key={key} className={priceCardClasses.textFormat}>
                      {option.text}{" "}
                      {option.additionCost && (
                        <span className={priceCardClasses["additionalCost"]}>
                          +{option.additionCost} €
                        </span>
                      )}
                      {option.additionalText && (
                        <span className={priceCardClasses["additionalText"]}>
                          ({option.additionalText})
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      <div className={priceCardClasses["toggleButton"]}>
        <ButtonWithIcon
          icon={<DropdownArrowIcon active={accordion} />}
          onClick={() => setAccordion(!accordion)}
        >
          <span className={priceCardClasses["toggleButtonText"]}>
            {accordion
              ? "Skryť detaily a extra služby"
              : "Zobraziť detaily a extra služby"}
          </span>
        </ButtonWithIcon>
      </div>
    </div>
  );
};

export default PriceCard;

import React from "react";
import palkovic from "../icons/faq-company/Palkovič/palkovic.png";
import vapeklub from "../icons/faq-company/vapeklub/vapeklub.png";
import VictorIcon from "../icons/faq-company/victor/victor.png";

export const BENEFITS_LIST = [
   "Zásielky doručujeme v Košiciach a blízkom okolí v deň objednávky do 20:00.",
   "Cena za doručenie zásielky neprekročí cenu štandardného kuriéra.",
   "Neúčtujeme palivový a mýtny príplatok.",
   "Akceptujeme platby kartou aj v hotovosti",
   "Akceptujeme platby kartou aj v hotovosti",
   "Doručenie v deň objednávky zvyšuje lojalitu zákazníka – až 90% nakupuje v e-shope pri tejto možnosti opäť.",
   "U našich e-shop partnerov zaznamenávame 1x zvýšenie objednávok.",
   "Nevyžadujeme min. objemy zásielok.",
   "Dobierkový poplatok je výrazne nižší ako u štandardných kuriérov.",
   "Pre Váš e-shop máme pripravené API na automatizáciu alebo webový objednávkový systém dostupný na business.doruc.to",
   "Zákazníkom odosielame automatické SMS o vyzdvihnutí a doručení zásielky.",
 ];

 export const FAQ_QUESTIONS_FOR_COMPANY = [
    {
    title:"Ako služba funguje?", 
    body: <p>Zásielky vyzdvihujeme u našich partnerov medzi 14:00 a 15:00 hodinou a doručujeme do 20:00. V prípade požiadavky zákazníka zásielku doručíme v želaný deň a čas.</p>,
   },
    {
       title:"Kde doručujete? ", 
       body: <p>Doručujeme v mestských častiach Košíc, teda PSČ 04001 - 04023 okrem Šace(PSČ 04015).</p>,
    },
    {
       title:"Aké máte ceny za zásielku? ", 
       body: <p>Ceny pre eshopové zásielky sa pohybujú od 2,10 do 3,00 EUR bez DPH, cenník vždy pripravujeme na mieru požiadavkám klienta.</p>,
    },
    {
       title:"Aké dodatočné poplatky účtujete?", 
       body: <p>Účtujeme len poplatok vo výške 1,5% z dobierkovej zásielky. Žiadne ďalšie poplatky(mýtny, palivový, cestný) u nás nemáme. Sme tak v priemere lacnejší o 50% ako štandardné kuriérske služby.</p>,
    },
    {
       title:"Ako vytvorím objednávku?", 
       body: <p>Po dohode Vám vytvoríme prihlasovacie údaje do našej webovej aplikácie doruč.to, kde viete zásielky nahrávať manuálne alebo cez exporty. K dispozícii máte prehľady, dobierkové zoznamy či možnosť tlačiť štítky</p>,
    },
    {
       title:"Ako vie zákazník o objednávke? ", 
      body: <p>Zákazníka v predstihu kontaktujeme o doručení a odosielame notifikačnú SMS.</p>,
    },
    {
       title:"Robíte dobierky?", 
         body: <p>Áno, dobierkovú zásielku vie zákazník uhradiť v hotovosti alebo platobnou kartou. Vyzbierané dobierkové platby následne preposielame 1x týždenne na Váš účet.</p>,
    },
     {
         title: "Máte k dispozícii API napojenie?",
         body: <p>Samozrejme, pre našich klientov poskytujeme aj napojenie na API.</p>
     }
   ]

   export const FEEDBACKS_FORM_COMPINIES = [
      {
         message: "Na doručto si pochvaľujeme hlavne rýchlosť a obratnosť. Nenazval by som to ani same day delivery, ale same hour delivery. Sú veľmi rýchli a profesionáli. Po skúsenosti aj s inými kuriérmi v Bratislave alebo Košiciach, doručto sú jednoznačne najlepší. Na kvalite služby veľmi cítiť to, že si zákazníka vážia a pracujú na tom aby splnili očakávania do poslednej bodky. Doručto sa to darí veľmi dobre.",
         companyName: "Palkovič",
         author: "Martin Palkovič",
         logo: palkovic
      },
      {
         message: "Kuriérska služba Doručto pomohla nášmu e-shopu zbúrať zaužívaný štandard, a tým je čas potrebný na doručenie zásielky. Zákazníci si pochvaľujú hlavne rýchlosť a flexibilitu. Je len málo e-shopov, ktoré zákazníkom doručia tovar v deň objednávky a my sme vďaka Doručto jedným z nich.",
         companyName: "Vapeklub",
         author: "Juraj Cicholes",
         logo: vapeklub,
      },
      {
         message: "Spolupráca s kuriérskou službou DORUČ.TO nám pomáha zastrešiť kompletnú logistiku donášok po meste Košice. Naša spokojnosť je odrazom toho, že zásielku prevezmú a doručia vždy načas, bezpečne a spoľahlivo. Tieto benefity oceňujú následne aj naši zákazníci. Kvalitu doručovacej služby môžeme úprimne odporučiť ďalej.",
         companyName: "Victor",
         author: "Dominika Kmecová",
         logo: VictorIcon
      },
   
   ]

